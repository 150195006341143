@import '../styles/variables';

.hero {
  background-color: $darker;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: white;
  padding: 0 7vw;

  .left-header {
    text-align: left;
    display: flex;
    flex-direction: column;
    font-size: 1.5em;
    justify-content: center;

    h1,
    h2 {
      margin: 0;
    }

    h3 {
      margin: 0.5em 0 0 0;
      font-size: 1.2em;
    }

    p {
      margin: 0;
      font-size: 0.8em;
    }
    .buy-ticket-button {
      margin-top: 0.5em;
    }
    .countdown {
      font-family: 'Bangers', serif;
      font-size: 1.25em;
      margin-top: 0.5em;
    }
  }

  .right-header {
    text-align: right;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: auto;
    }
  }

  .buy-ticket-button {
    text-decoration: none;
    color: $white;
    border: 4px solid $white;
    border-radius: 10px;
    padding: 0.5em 1em;
    max-width: max-content;
    font-family: 'Bangers', serif;

    &:hover {
      background-color: $white;
      color: $darker;
    }
  }

  //todo: portrait mode styling needs to be fixed and imporved
  @media (orientation: portrait) {
    grid-template-columns: 1fr;

    .buy-ticket-button {
      font-size: 0.8em;
    }

    .left-header {
      display: block;
      padding-top: 12vh;

      .countdown {
        margin-bottom: 1em;
      }
    }
    .right-header {
      display: block;
    }
  }
}
