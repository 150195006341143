@import '../styles/variables';

.navbar {
  background-color: var(--background-color);
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 0 7vw;
  position: fixed;
  width: 100vw;
  z-index: 1000;
  box-sizing: border-box;
  font-family: 'Bangers', serif;
}

.navbar h1 {
  color: var(--text-color);
  font-size: 3rem;
}

.navbar__branding {
  height: 100%;
  margin-right: auto;
  display: flex;
  align-items: center;
  flex-direction: row;

  a {
    text-decoration: none;
  }
}

.navbar__logo {
  max-height: 100%;
}

.navbar__links {
  height: 100%;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.navbar__links a {
  color: var(--text-color);
  font-size: 1.8rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 1rem;
}

@media (orientation: portrait) {
  .navbar {
    display: grid;
    grid-template-rows: auto 1fr;
    justify-items: center;
    height: 5rem;
  }

  .navbar__branding {
    margin-right: 0;
    height: 3rem;
  }

  .navbar__links {
    margin-left: 0;
    height: 2rem;
  }

  .navbar h1 {
    font-size: 2rem;
  }

  .navbar__links a {
    font-size: 1.5rem;
  }
}
