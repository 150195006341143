@import '../styles/variables';

.textbox {
  width: 80%;
  padding: 2% 3%;
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: $darker;
  border-radius: 10px;

  h3 {
    display: flex;
    margin: 0;
    color: $white;
  }
  p,
  a {
    text-align: start;
    color: $white;
    margin: 0;
    font-size: 1rem;
  }

  a {
    color: $linkLight;
    text-decoration: underline;

    &:hover {
      color: $linkLightHover;
    }
  }
}

.textbox > a {
  margin-top: 1em;
  text-decoration: none;
  color: $white;
  border-radius: 10px;
  padding: 0.7em 1.2em;
  max-width: max-content;
  background-color: $dark;
  font-family: 'Bangers', serif;

  &:hover {
    background-color: $white;
    color: $darker;
  }
}
