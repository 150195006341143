.tips {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5vh;

  .textbox {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }

    &:nth-child(1) { transition-delay: 0.2s; }
    &:nth-child(2) { transition-delay: 0.4s; }
  }
}

@media (orientation: portrait) {
  .tips {
    .textbox {
      opacity: 0;
      transform: translateY(20px);

      &:nth-child(n) {
        transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        transition-delay: 0.2s; // Consistent delay for all cards
      }

      &.visible {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}