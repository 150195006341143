@import '../styles/variables';

.rules {
    background-color: $darker;
    color: $white;
	min-height: 100vh;
}

.content-section {
	padding-top: 4rem;
}

.aditional {
	min-height: calc(100vh - 4rem); // Subtract navbar height
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 25vw;
	padding: 20px 0;
	flex-direction: column; 
}

.aditionalText{
	width: 100%;
	padding-bottom: 30px;
}

.aditionalHarmonica{
	width: 100%;
}

.harmonicaItem{
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	border-bottom: 3px solid #ccc;
}

.harmonicaTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.harmonicaContent {
	align-self: center;
	width: 90%;
	transition: max-height 1s linear;
}


.harmonicaItem.open .harmonicaContent {
	max-height: 100vh; /* Set a large value to ensure content fully expands */
	margin-bottom: 1rem;
	margin-top: 0.5rem;
}

.harmonicaArrow {
	height: 100%;
	filter: invert(100%);
}

.harmonicaArrowImg{
	height: 100%;
}

@media screen and (max-width: 1160px){
	.aditional{
		margin: 0 5vw;
	}

	.harmonicaContent {
		width: 80%;
	}
}