@import '../styles/variables';


.cards {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 2vw;

  .card {
    color: var(--white);
    background-color: var(--darker);
    padding: 15px;
    width: 20vw;
    border-radius: 10px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }

    &:nth-child(1) { transition-delay: 0.2s; }
    &:nth-child(2) { transition-delay: 0.6s; }
    &:nth-child(3) { transition-delay: 1.0s; }

    p {
      text-align: left;
      font-size: 1em;
    }
    h2 {
      padding: 10px 0 0 0;
    }
  }

  img {
    width: 100%;
  }

  .social {
    display: flex;
    flex-direction: row;
  }

  .social img {
    width: auto;
    height: 2.75em;
  }

  .card:first-child {
    background-image: url('./Images/left_child.webp');
    background-size: cover;
    background-position: center;
  }

  .card:last-child {
    background-image: url('./Images/right_child.webp');
    background-size: cover;
    background-position: center;
  }
}

@media (orientation: portrait) {
  .cards {
    flex-direction: column;
    align-items: center;

    .card {
      width: 80vw;
      margin: 10px;
    }

    &:nth-child(1) { transition-delay: 0.2s; }
    &:nth-child(2) { transition-delay: 0.2s; }
    &:nth-child(3) { transition-delay: 0.2s; }
  }
}