:root {
  --black: #000;
  --darker: #151515;
  --dark: #1e1e1e;
  --kindadarkish: #444;
  --darkish: #777;
  --gray: #aaa;
  --grayish: #ccc;
  --silver: #e2e2e2;
  --silverish: #eee;
  --light: #f4f4f4;
  --white: #fff;
  --linkLight: #c0c0ff;
  --linkLightHover: #9090ff;

  --navy-blue: #002a61;
}

$black: var(--black);
$darker: var(--darker);
$dark: var(--dark);
$kindadarkish: var(--kindadarkish);
$darkish: var(--darkish);
$gray: var(--gray);
$grayish: var(--grayish);
$silver: var(--silver);
$silverish: var(--silverish);
$light: var(--light);
$white: var(--white);
$linkLight: var(--linkLight);
$linkLightHover: var(--linkLightHover);

$navy-blue: var(--navy-blue);
