.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    img {
        height: 80px;
        margin-top: 20px;
    }
    p {
        font-family: "Bangers", serif;
        font-size: 1em;
    }
    .footerTopItem {
        margin: 10px 0 0 0;
    }
    .footerMidItem {
        margin: 0; 
    }
    .footerBotItem {
        margin: 0 0 15px 0;
    }
    
}

