@import '../styles/variables';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// text styling
p {
  font-size: 1.2em;
}

.whatwherewho-homepage {
  padding-top: 10vh;
}

.lineup-homepage,
.previous-homepage, .footer {
  background-color: $darker;
  h2 {
    margin: 0;
    padding: 5vh 0;
    color: $white;
  }
}

.schedule-homepage,
.tips-homepage,
.whatwherewho-homepage {
  padding-bottom: 10vh;
  background-color: $dark;
  h2 {
    margin: 0;
    padding: 5vh 0;
    color: $white;
  }
}
