@import '../styles/variables';

.previous {
  width: 85vw;
  padding: 0 7vw 10vh 7vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5vw;
  align-items: baseline;

  .edition {
    img {
      width: 100%;
      border-radius: 10px;
    }

    h3 {
      display: flex;
      margin-top: 0.5em;
      color: $white;
    }
  }
  @media (orientation: portrait) {
    grid-template-columns: 1fr;
  }
}
