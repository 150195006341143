.lineup {
  width: 85vw;
  padding-bottom: 10vh;
}
.lineup-mobile {
  display: none;
  padding-bottom: 10vh;
  width: 85vw;
}

@media (orientation: portrait) {
  .lineup {
    display: none;
  }
  .lineup-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto; // Center horizontally
  }
}
